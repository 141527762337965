@font-face {
  font-family: Poppins-Regular;
  src: url("./fonts/poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins-Medium;
  src: url("./fonts/poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: Poppins-Bold;
  src: url("./fonts/poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: Poppins-SemiBold;
  src: url("./fonts/poppins/Poppins-SemiBold.ttf");
}

@font-face {
  font-family: Lato-Italic;
  src: url("./fonts/lato/Lato-Italic.ttf");
}

@font-face {
  font-family: Lato-Light;
  src: url("./fonts/lato/Lato-Light.ttf");
}

@font-face {
  font-family: Lato-Regular;
  src: url("./fonts/lato/Lato-Regular.ttf");
}

@font-face {
  font-family: Lato-Bold;
  src: url("./fonts/lato/Lato-Bold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: Lato-Black;
  src: url("./fonts/lato/Lato-Black.ttf");
  font-weight: 800;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  --main-font: "Lato-Regular";
  font-family: var(--main-font);
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.downloadBarInner {
  height: 100%;
  background-color: #395e9d;
  border-radius: 6px;
}
@-ms-viewport {
  width: device-width;
}

article,
aside,
dialog,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

body {
  margin: 0;
  font-family: var(--main-font);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

.minHeightFitContent {
  min-height: min-content !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

dfn {
  font-style: italic;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}

a:hover {
  color: #0056b3;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  -ms-overflow-style: scrollbar;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg:not(:root) {
  overflow: hidden;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}
input,
input:focus,
input:active {
  outline: transparent 0px;
  box-shadow: none;
  border: none !important;
  box-sizing: unset !important;
}

button,
select {
  text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 0px inset;
  box-shadow: 0 0 0px 0px inset;
  transition: background-color 5000s ease-in-out 0s;
}

textarea {
  overflow: auto;
  resize: vertical;
}

.padCal {
  padding: 5%;
}
.collapsed {
  display: none;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

/* Login CSS */
.wrapper {
  margin: 0 auto;
  height: 100% !important;
  width: 100% !important;
}

.wrapper-mobile {
  margin: 0 auto;
  background-repeat: no-repeat !important;
  background-position: center bottom !important;
  height: 100% !important;
  width: 100% !important;
}

.custom-file-upload {
  color: white !important;
  background-color: #2bc2f7;
  border-radius: 10px;
  width: 24%;
  font-size: small;
  display: flex;
  flex-direction: row;
  padding: 10px 4px;
  justify-content: space-around;
  align-items: center;
}

.custom-file-upload-2 {
  color: white !important;
  background-color: #2bc2f7;
  border-radius: 10px;
  width: 24%;
  font-size: small;
  display: flex;
  max-height: 40px;
  flex-direction: row;
  padding: 10px 4px;
  justify-content: space-around;
  align-items: center;
}

.chat-image-upload {
  color: white !important;
  background-color: #2bc2f7;
  border-radius: 10px;
  font-size: small;
  display: flex;
  flex-direction: row;
  padding: 10px 4px;
  justify-content: space-around;
  align-items: center;
}
.rounded-image {
  margin-left: -135px;
}

.customLink {
  color: #395e9d;
  font-weight: bold;
}

.customLink:hover {
  text-decoration: none;
}

.eclipse-image {
  transform: rotate(0.1deg);
  margin-left: -70px;
  margin-top: 60px;
}

.loginInput {
  color: black !important;
  font-size: small !important;
  max-width: 90%;
}

.loginInput:focus,
.passwordInput:focus {
  outline: transparent !important;
  outline-width: 0px !important;
  box-shadow: none !important;
  border: none !important;
  outline-width: 0px !important;
}
.activePage {
  background-color: #395e9d;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  color: white !important;
  width: 21px;
  height: 21px;
  justify-content: center;
}
.inactivePage {
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  color: #395e9d !important;
  width: 21px;
  height: 21px;
  justify-content: center;
}

.semiInput {
  color: black !important;
  font-size: small !important;
  font-weight: bold !important;
  background: #fbfbfb;
  width: 100%;
  outline: transparent !important;
  outline-width: 0px !important;
  box-shadow: none !important;
  border: none !important;
}

.semiInput :focus {
  outline: transparent !important;
  outline-width: 0px !important;
  box-shadow: none !important;
  border: none !important;
}

.semiInput :active {
  outline: transparent !important;
  outline-width: 0px !important;
  box-shadow: none !important;
  border: none !important;
}
.semiInputBulk {
  color: black !important;
  font-size: small !important;
  font-weight: bold !important;
  width: 100%;
}

.semiInputBulkDisabled {
  color: #21252945 !important;
  background-color: white !important;
  font-size: small !important;
  font-weight: bold !important;
  width: 100%;
}

.semiInput:focus {
  outline: transparent !important;
}

.customInput {
  min-height: 40px !important;
}

.customInputDatePick {
  min-height: 42px !important;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  padding: 7px;
  background: #fbfbfb;
  margin-top: 6px !important;
}

.passwordInput {
  color: black !important;
  font-size: small !important;
  max-width: 92%;
}
.searchInput {
  color: black !important;
  font-size: small !important;
  max-width: 92%;
}

.whiteBack {
  background-color: white;
}

input[type="file"] {
  display: none;
}

.wrap-login {
  background: #fff;
  overflow: hidden;
  padding: 0px 22px 33px 22px;
}
.login100-form-title {
  display: block;
  font-family: "Poppins-Bold";
  font-size: 32px !important;
  color: #333333;
  line-height: 1.4;
  text-align: center;
  margin: 24px 0 !important;
}

.schedule-demo {
  color: #2bc2f7;
  text-decoration: dashed;
  transition: color 250ms ease-in-out, border-bottom 250ms ease-in-out;
  border-bottom: 1px dotted #2bc2f7;
}

.schedule-demo:hover {
  border-bottom: 1px dotted #0056b3;
  text-decoration: none;
}

label {
  color: #5a32aa !important;
}

@media (max-width: 576px) {
  .wrap-login {
    padding: 33px 15px 33px 15px;
  }
}

.card-attachment {
  background: #ffffff;
  padding: 24px;
  box-shadow: 0px 10px 30px -10px rgb(80, 110, 131, 15%);
  border-radius: 20px;
}

/*  */
.card {
  background: #ffffff;
  padding: 24px;
  width: 100% !important;
  box-shadow: 0px 10px 30px -10px rgb(80, 110, 131, 15%);
  border-radius: 20px;
}

.card:focus {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.13) !important;
}

.card-home {
  transition-property: box-shadow, color;
  transition: 150ms ease-in-out;
  justify-content: space-between;
  height: 100%;
}

.card-home:hover {
  box-shadow: 0px 3px 20px -10px rgba(80, 110, 131, 0.6);
}

.card-home > span {
  transition-property: color;
  transition: 200ms ease-in-out;
}

.card-home:hover > span {
  color: #2bc2f7;
}

.card-home > span::after {
  transition-property: transform, opacity;
  transition: 200ms ease-in-out;

  content: "\2192";
  color: #2bc2f7;
  display: inline-block;
  opacity: 0;
  transform: translateX(-8px);
}

.card-home:hover > span::after {
  opacity: 1;
  transform: translateX(10px);
}

.card-mobile {
  background: #ffffff;
  padding: 24px;
  width: 100% !important;
  box-shadow: 0px 10px 30px -10px rgb(80, 110, 131, 15%);
  border-radius: 20px;
}

.popup-card {
  background: #ffffff;
  padding: 24px 32px;
  color: #1d3763;
  max-width: 425px !important;
  box-shadow: 0 100vh 0 100vw rgb(0 0 0 / 80%);
  border-radius: 10px !important;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.popup-assessment {
  background: #ffffff;
  padding: 24px 32px;
  color: #1d3763;
  height: 90%;
  max-width: 80% !important;
  box-shadow: 0 100vh 0 100vw rgb(0 0 0 / 80%);
  border-radius: 10px !important;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.popup-tiktok {
  background: #ffffff;
  box-shadow: 0 100vh 0 100vw rgb(0 0 0 / 80%);
  border-radius: 10px !important;
  height: 90% !important;
}
.popup-card-payment {
  background: #ffffff;
  padding: 24px 32px;
  color: #1d3763;
  box-shadow: 0 100vh 0 100vw rgb(0 0 0 / 80%);
  border-radius: 10px !important;
  width: 50%;
  align-items: center;
  justify-content: center;
}

.popup-card-flag {
  background: #ffffff;
  padding: 24px 32px;
  color: #1d3763;
  box-shadow: 0 100vh 0 100vw rgb(0 0 0 / 80%);
  border-radius: 10px !important;
  width: 30%;
  align-items: center;
  justify-content: center;
}

.popup-card-fireFlag {
  background: #ffffff;
  padding: 24px 32px;
  /* color: #1d3763; */
  box-shadow: 0 100vh 0 100vw rgb(0 0 0 / 80%);
  border-radius: 10px !important;
  width: 65%;
  align-items: center;
  justify-content: center;
}
.popup-card-override {
  background: #ffffff;
  padding: 24px 32px;
  color: #1d3763;
  box-shadow: 0 100vh 0 100vw rgb(0 0 0 / 80%);
  border-radius: 10px !important;
  width: 50%;
  align-items: center;
  justify-content: center;
}
.popup-post {
  background: #ffffff;
  color: #1d3763;
  height: 98%;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 100vh 0 100vw rgb(0 0 0 / 80%);
  border-radius: 10px !important;
  width: 80%;
}

.popup-post-audio {
  background: #ffffff;
  color: #1d3763;
  box-shadow: 0 100vh 0 100vw rgb(0 0 0 / 80%);
  border-radius: 10px !important;
  width: 80%;
}
.popup-entry {
  background: #ffffff;
  padding: 24px 32px;
  color: #1d3763;
  height: 30%;
  box-shadow: 0 100vh 0 100vw rgb(0 0 0 / 80%);
  border-radius: 10px !important;
  width: 40%;
}
.popup-invite {
  background: #ffffff;
  padding: 24px 32px;
  color: #1d3763;
  box-shadow: 0 100vh 0 100vw rgb(0 0 0 / 60%);
  border-radius: 10px !important;
  width: 45%;
}
.checkBoxCustom {
  color: red !important;
}
.popup-sales {
  background: #ffffff;
  padding: 24px 32px;
  min-height: fit-content;
  max-height: fit-content;

  color: #1d3763;
  box-shadow: 0 100vh 0 100vw rgb(0 0 0 / 60%);
  border-radius: 10px !important;
  width: 60%;
}

.popup-details {
  background: #ffffff;
  padding: 24px 32px;
  color: #1d3763;
  overflow-y: auto;
  box-shadow: 0 100vh 0 100vw rgb(0 0 0 / 60%);
  border-radius: 10px !important;
  width: 80%;
  max-height: 90% !important;
}

.modalPopper {
  transform: translate3d(40px, 2px, 0px) !important;
}

.react-datepicker__header,
.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-names,
.react-datepicker__day-name {
  color: white !important;
  background: #1c3664 !important;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  color: black !important;
  background: white !important;
}
.popup-answers {
  background: #ffffff;
  padding: 24px 32px;
  color: #1d3763;
  overflow-y: auto;
  box-shadow: 0 100vh 0 100vw rgb(0 0 0 / 60%);
  border-radius: 10px !important;
  width: 70%;
  height: 90%;
}
.popup-medium {
  background: #ffffff;
  padding: 24px 32px;
  color: #1d3763;
  box-shadow: 0 100vh 0 100vw rgb(0 0 0 / 60%);
  border-radius: 10px !important;
  width: 70%;
  height: 45%;
}
.fileUpload {
  font-size: small;
  color: red;
}
.popup-invite-bulk {
  background: #ffffff;
  padding: 24px 32px;
  color: #1d3763;
  overflow-y: auto;
  box-shadow: 0 100vh 0 100vw rgb(0 0 0 / 60%);
  border-radius: 10px !important;
  width: 50%;
}

.popup-enrollments {
  background: #ffffff;
  padding: 24px 32px;
  color: #1d3763;
  overflow-y: auto;
  box-shadow: 0 100vh 0 100vw rgb(0 0 0 / 60%);
  border-radius: 10px !important;
  width: 70%;
}
.popup-update {
  background: #ffffff;
  padding: 24px 32px;
  color: #1d3763;
  height: 100%;
  overflow-y: auto;
  box-shadow: 0 100vh 0 100vw rgb(0 0 0 / 60%);
  width: 30%;
}
.popup-chat {
  background: #ffffff;
  padding: 5px;
  color: #1d3763;
  height: 100%;
  overflow-y: auto;
  box-shadow: 0 100vh 0 100vw rgb(0 0 0 / 60%);
  width: 50%;
}
.selectCustom {
  border: 1px solid #395e9d !important;
  width: 110% !important;
}

.optionContainer {
  border-radius: 10px;
  font-size: small;
}

.chip {
  background: #395e9d !important;
}

.searchBox {
  border: none;
  font-size: small;
}
.popup-card-mobile {
  background: #ffffff;
  padding: 5%;
  margin-top: 50%;
  box-shadow: 0 0 100vh 100vw rgb(0 0 0 / 80%);
  color: #1d3763;
  border-radius: 10px !important;
  max-height: 25%;
  height: 100%;
  width: 80%;
  align-self: center;
  align-items: center;
  justify-items: center;
  align-content: center;
  justify-content: center;
}
.large-card-red {
  background: #ffffff;
  padding: 24px;
  min-width: 48% !important;
  box-shadow: 0px 10px 30px -10px rgb(80, 110, 131, 15%);
  border-radius: 20px;
  height: 100%;
  border-top: 3px solid #fa8581;
}
.large-card {
  background: #ffffff;
  min-width: 48% !important;
  box-shadow: 0px 10px 30px -10px rgb(80, 110, 131, 15%);
  border-radius: 10px;
  width: 100%;
}
.large-card-notes {
  background: #ffffff;
  min-width: 48% !important;
  min-height: 400px !important;
  box-shadow: 0px 10px 30px -10px rgb(80, 110, 131, 15%);
  border-radius: 10px;
  width: 100%;
}

.large-card.latest-assessment {
  min-width: 0 !important;
  width: auto;
  /* flex-grow: 1; */
}

.large-card-summary {
  background: #ffffff;
  min-width: 48% !important;
  border-radius: 10px;
  width: 100%;
  border: 0.5px solid #e0e0e0;
  border-radius: 8px;
}

.large-card-resources {
  background: #ffffff;
  box-shadow: 0px 10px 30px -10px rgb(80, 110, 131, 15%);
  width: fit-content;
  border-radius: 10px;
  max-height: 300px !important;
  min-height: 300px !important;
}

.large-card-settings {
  background: #ffffff;
  box-shadow: 0px 10px 30px -10px rgb(80, 110, 131, 15%);
  width: fit-content;
  border-radius: 10px;
  max-height: 220px !important;
  min-height: 150px !important;
}

.active-tab {
  padding: 15px 10px !important;
  color: #395e9d !important;
  font-weight: bold !important;
  background-color: #e2f3f9 !important;
}

.tab-button {
  padding: 15px 10px !important;
}

.deck-card {
  background: #ffffff;
  box-shadow: 0px 10px 30px -10px rgb(80, 110, 131, 15%);
  border-radius: 20px;
  min-height: 170px !important;
}

.path-card {
  background: #ffffff;
  box-shadow: 0px 10px 30px -10px rgb(80, 110, 131, 15%);
  border-radius: 20px;
}

.pinCollapse {
  padding: 10px 10px 0px 10px !important;
}

.large-card-bread {
  background: #ffffff;
  min-width: 48% !important;
  box-shadow: 0px 10px 30px -10px rgb(80, 110, 131, 15%);
  width: 100%;
  position: sticky;
  align-self: flex-start;
}

.large-card-sharp {
  background: #ffffff;
  min-width: 48% !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.13);
  /* width: 98%; */
  /* border-radius: 12px !important; */
}

.large-card-billing {
  background: #ffffff;
  border: 0.5px solid #e0e0e0;
  border-radius: 8px;
  min-width: 48% !important;
  width: 98%;
  min-height: 250px !important;
}
.large-card-messages-new {
  background: #ffffff;
  padding: 24px;
  min-width: 48% !important;
  box-shadow: 0px 10px 30px -10px rgb(80, 110, 131, 15%);
  border-radius: 20px;
  width: 100%;
  height: 100%;
  border-top: 3px solid #74c9c1;
}
.large-card-messages {
  background: #ffffff;
  padding: 24px;
  min-width: 48% !important;
  box-shadow: 0px 10px 30px -10px rgb(80, 110, 131, 15%);
  border-radius: 20px;
  width: 100%;
  height: 100%;
}

.collapsible-menu {
  width: 100% !important;
}

.hamburger-row:focus {
  outline: none !important;
  box-shadow: none !important;
}

.large-card-yellow {
  background: #ffffff;
  padding: 24px;
  min-width: 48% !important;
  box-shadow: 0px 10px 30px -10px rgb(80, 110, 131, 15%);
  border-radius: 20px;
  height: 100%;
  border-top: 3px solid #e8c64f;
}

.logoutButton:hover {
  background: rgba(250, 133, 129, 0.2);
}
.logoutButton {
  border-radius: 10px;
  width: 176px;
  padding: 12px 16px;
  align-self: center;
}

button:focus {
  box-shadow: none !important;
}

.noHover:focus {
  outline-width: 0px !important;
  box-shadow: none !important;
  border: none !important;
}

.noHover:checked + span > span {
  background: white !important;
}
.headingline {
  border: 1px solid #c4c4c4;
  opacity: 0.4;
}

.labelText {
  color: white !important;
}

.btn {
  border-radius: 10px !important;
  color: white !important;
  height: 40px;
  width: 200px;
  background-color: #395e9d !important;
}
.menu {
  border-radius: 10px !important;
  align-items: center;
  justify-content: center;
  color: white !important;
  background-color: #395e9d !important;
  width: 200px;
  align-content: center !important;
}

.dropMenu {
  border-radius: 10px !important;
  font-size: small !important;
  color: #696974 !important;
}

.btn-fullwidth {
  border-radius: 10px !important;
  color: white !important;
  height: 40px;
  width: 100%;
  background-color: #395e9d !important;
}
.btn-inverse {
  border-radius: 10px !important;
  color: #395e9d !important;
  height: 40px;
  width: 200px;
  background-color: #c4c4c448 !important;
}

.btn-profile-dark {
  border-radius: 10px !important;
  color: white !important;
  background-color: #395e9d !important;
  height: 45px;
}

.btn-profile-light {
  border-radius: 10px !important;
  color: #28539e !important;
  width: 150px;
  background-color: #f1f1f5 !important;
  height: 45px;
}

.btn-profile-light-mobile {
  border-radius: 10px !important;
  color: #28539e !important;
  background-color: #f1f1f5 !important;
  height: 45px;
}

.btn-yes {
  border-radius: 10px !important;
  color: white !important;
  width: 120px;
  padding: 7px 12px !important;
  background-color: #395e9d !important;
}

.btn-yes-small {
  border-radius: 10px !important;
  color: white !important;
  width: 70px;
  padding: 5px 5px !important;
  background-color: #395e9d !important;
}

.btn-trans {
  border-radius: 8px !important;
  color: #1c3664 !important;
  border: 1px solid #395e9d !important;
  width: 120px;
  padding: 8px 16px !important;
  background-color: transparent !important;
}

.myDate {
  background-color: white !important;
  pad: 0;
  max-width: 100px;
}

.btn-end-session {
  border-radius: 10px !important;
  color: white !important;
  border: none;
  box-shadow: none;
  font-size: 14px;
  padding: 8px 26px !important;
  background-color: #e86661 !important;
}
.btn-no {
  border-radius: 10px !important;
  color: white !important;
  border: none;
  box-shadow: none;
  width: 120px;
  padding: 7px 12px !important;
  background-color: #e86661 !important;
}

.btn-no-small {
  border-radius: 10px !important;
  color: white !important;
  border: none;
  box-shadow: none;
  width: 70px;
  padding: 5px 5px !important;
  background-color: #e86661 !important;
}
.yFlag {
  background-color: #fddc32;
  color: #4f4f4f;
  border-radius: 4px;
}
@keyframes widthIncrease {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}
.animateNotif {
  animation-iteration-count: 1;
  width: 100%;
  animation-fill-mode: forwards;
  height: 5px;
  animation-name: widthIncrease;
  animation-duration: 5.9s;
  animation-timing-function: linear;
}
.offline_error {
  position: fixed;
  transform: translate(-50%, 0);
  bottom: 20px;
  padding: 10px 20px;
  box-shadow: 0 0 10px 5px;
  color: #00000099;
  font-family: Poppins-Regular;
  font-size: 16px;
  left: 50%;
  background-color: white;
  border-radius: 10px;
}

.gFlag {
  background-color: #8dc779;
  color: white;
  border-radius: 4px;
}
.rFlag {
  background-color: #e86661;
  color: white;
  border-radius: 4px;
}

.pFlag {
  background-color: #fabfbf;
  color: #eb5757;
  padding: 3px 8px;
  border-radius: 4px;
}

.iFlag {
  background-color: #33333320;
  color: #333333;
  border-radius: 4px;
  padding: 4px 10px;
}

.bFlag {
  background-color: #d8dee941;
  color: #1b3664;
  border-radius: 4px;
  padding: 4px 10px;
}

.pmFlag {
  background-color: #74c9c1;
  color: white;
  border-radius: 4px;
  padding: 4px 10px;
}

.depFlag {
  background-color: #fa8581;
  color: white;
  border-radius: 4px;
  padding: 4px 10px;
}

.anxietyFlag {
  background-color: #b0b4ff;
  color: white;
  border-radius: 4px;
  padding: 4px 10px;
}

.minContentHeight {
  min-height: max-content !important;
}

.reportReady {
  background-color: #88dcbe;
  color: white;
  padding: 5% 2%;
  border-radius: 6px;
}

.reportMissing {
  background-color: #fa8581;
  color: white;
  padding: 2% 1%;
  border-radius: 6px;
}

.nmFlag {
  background-color: #0c0c0c;
  color: white;
  border-radius: 4px;
  padding: 4px 10px;
}

.noOutline {
  outline: transparent !important;
  outline-width: 0px !important;
  box-shadow: none !important;
  border: none !important;
  outline-width: 0px !important;
}

.noOutline :focus {
  outline: transparent !important;
  outline-width: 0px !important;
  box-shadow: none !important;
  border: none !important;
}

.noOutline :active {
  outline: transparent !important;
  outline-width: 0px !important;
  box-shadow: none !important;
  border: none !important;
}
.noOutlineWithBorder {
  outline: transparent !important;
  outline-width: 0px !important;
  box-shadow: none !important;
  outline-width: 0px !important;
}

.noOutlineWithBorder :focus {
  outline: transparent !important;
  outline-width: 0px !important;
  box-shadow: none !important;
}

.noOutlineWithBorder :active {
  outline: transparent !important;
  outline-width: 0px !important;
  box-shadow: none !important;
}

.gFlag {
  background-color: #8dc779;
  color: whtie;
  border-radius: 4px;
  padding: 4px 10px;
  margin-left: 2%;
}

.result-tag {
  background-color: #a1ddec50;
  color: #2bc2f7;
  border-radius: 4px;
  font-weight: bold;
}

.btn-dark {
  border-radius: 10px !important;
  color: white !important;
  background-color: #1d3763 !important;
}

.breadcrumb {
  position: fixed;
  top: 0;
  z-index: 10;
  width: calc(100% - 270px);
  box-shadow: 3px 3px 3px rgba(0, 0, 0, 0.25);
}
.tab {
  width: 500px;
  max-width: 30%;
}
.tab2 {
  width: 20%;
}
.tab3 {
  width: 50%;
  background-color: #e0e0e050 !important;
  padding: 2% !important;
  margin-top: 2% !important;
  margin-bottom: 2% !important;
}

/* ::-webkit-scrollbar {
  display: none;
} */

.tab3:focus,
.tab3:active,
.tab3-active {
  width: 50%;
  background-color: #e0e0e0 !important;
  padding: 2% !important;
  margin-top: 2% !important;
  margin-bottom: 2% !important;
}
.tab3 > div {
  align-items: center !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.tab2 > div {
  align-items: center !important;
}

.tabSale > div {
  padding: none !important;
  margin-left: 0px !important;
}
.tab > div {
  align-items: center !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
}

@media (max-width: 425px) {
  .btn-dark {
    width: 100% !important;
  }
}

.btn-primary {
  border-radius: 10px !important;
  color: white !important;
  background-color: #2bc2f7 !important;
  font-family: var(--main-font);
  min-width: 90px;

  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
}

.btn-primary-last-view {
  border-radius: 10px !important;
  color: white !important;
  /* background-color: #2bc2f7 !important; */
  font-family: var(--main-font);
  min-width: 90px;

  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
}

.btn-primary-mobile {
  border-radius: 10px !important;
  color: white !important;
  background-color: #82d4e7 !important;
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
}
.btn-primary-mobile-last-view {
  border-radius: 10px !important;
  color: white !important;
  /* background-color: #82d4e7 !important; */
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
}

.btn-mobile {
  border-radius: 10px !important;
  color: white !important;
  background-color: #82d4e7 !important;
  font-family: var(--main-font);
  text-align: center;
}

.btn-primary-2D9CDB {
  border-radius: 7px !important;
  color: white !important;
  background-color: #2d9cdb !important;
  font-family: var(--main-font);
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0px;
  text-align: center;
}
.btn-primary-dark {
  border-radius: 10px !important;
  color: white !important;
  /* background-color: #395e9d !important; */
  font-family: var(--main-font);
  font-size: 12px;
  font-style: normal;
  min-width: 90px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
}

.btn-current {
  border-radius: 10px !important;
  color: #395e9d !important;
  border: 2px solid #395e9d !important;
  font-family: var(--main-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
}

.btn-primary-dark-mobile {
  border-radius: 10px !important;
  color: white !important;
  /* background-color: #395e9d !important; */
  font-family: var(--main-font);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
}
.btn-pins-dark {
  border-radius: 6px !important;
  color: white !important;
  background-color: #395e9d !important;
  font-weight: 600;
  min-width: 82px;
  text-align: center;
}

.btn-pins {
  border-radius: 6px !important;
  color: #395e9d !important;
  background-color: white !important;
  font-weight: 600;
  border: 1px solid #395e9d !important;
  text-align: center;
}
.activeClass {
  background-color: #c7efff;
  border: 1px solid #82d4e7;
  border-radius: 5px;
  text-transform: uppercase;
  color: #2d9cdb;
  padding: 3% 7%;
}
.inActiveClass {
  background-color: #ffd9d8;
  border-radius: 5px;
  border: 1px solid #eb5757;

  text-transform: uppercase;
  color: #eb5757;
  padding: 3% 7%;
}
.inTextSelect {
  background-color: #d8dee9 !important;
  color: #395e9d !important;
  border-radius: 13px !important;
  display: flex;
  justify-content: center;
  align-content: center;
}

.inTextSelect ::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #395e9d !important;
  opacity: 1; /* Firefox */
  font-weight: 400;
}
.btn-primary-teal {
  border-radius: 10px !important;
  color: white !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;

  background-color: #74c9c1 !important;
  font-family: var(--main-font);
  text-align: center;
}
.btn-profile {
  border-radius: 10px !important;
  color: white !important;
  width: 190px;
  background-color: #2bc2f7 !important;
  height: 45px;
}

table thead th {
  border-bottom: none !important;
}
.blueTable thead tr,
.blueTable thead th:last-child,
.blueTable thead th:first-child {
  background-color: #395e9d !important;
  color: white !important;
  border-radius: 0px 0px 0px 0px !important;
}

table thead th:first-child {
  border-bottom: none !important;
  border-radius: 10px 0px 0px 10px !important;
}
table thead th:last-child {
  border-bottom: none !important;
  border-radius: 0px 10px 10px 0px !important;
}

table thead tr {
  background-color: #fafafb !important;
}

.jBook {
  max-height: 256px;
}
.jBook thead tr {
  background-color: #edf3ff !important;
}

table tbody tr {
  border-bottom: 1px solid #c4c4c450;
}

table tbody tr:last-child {
  border-bottom: none !important;
}
.messageBox {
  height: 24px;
  width: 24px;
}
.sessionTable table tbody tr {
  height: 64px !important;
  border-bottom: 1px solid #c4c4c450;
}

.Tooltip {
  color: white !important;
  background-color: #395e9d !important;
  overflow: hidden;
  width: 24px;
  height: 24px;
  max-width: 200px !important;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
}

.TooltipDanger {
  color: white !important;
  background-color: #e86661 !important;
  overflow: hidden;
  width: 24px;
  height: 24px;
  max-width: 200px !important;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
}

.Tooltip-grey {
  color: white !important;
  background-color: #c4c4c4 !important;
  width: 24px;
  height: 24px;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
}

#log {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 550px;
  overflow-y: auto;
  font-size: 1.25rem;
  margin: 0 auto 1rem;
  padding: 0.5rem 1.5rem;
}

.recievedMessage {
  align-self: flex-start;
  position: relative;
  margin-top: 20px;
  margin-left: 20px;
  font-size: 16px;
  border-radius: 0px 8px 8px 8px;
  max-width: 70%;
  padding: 0.5rem 0.875rem;
  background-color: white;
  color: #333333;
}

.recievedMessage::before,
.recievedMessageImage::before {
  content: "";
  position: absolute;
  left: 2%;
  top: 0;
  width: 0;
  height: 0;
  border: 31px solid transparent;
  border-right-color: white;
  border-left: 0;
  border-top: 0;
  margin-top: 0;
  margin-left: -31px;
}

.recievedMessageImage {
  align-self: flex-start;
  position: relative;
  margin-top: 20px;
  border-radius: 0px 8px 8px 8px;

  margin-left: 20px;
  padding: 0.5rem 0.875rem;

  background-color: white;
  color: #000;
}

.recievedMessageImage > img {
  max-width: 200px;
}

.sentMessage {
  align-self: flex-end;
  position: relative;
  margin-top: 20px;
  border-radius: 8px 0px 8px 8px;
  padding: 0.5rem 0.875rem;
  font-size: 16px;
  max-width: 70%;
  background-color: #395e9d;
  color: white;
}

.unreadBadge {
  color: #1b3664 !important;
  font-size: x-small;
  justify-content: center;
  align-content: center;
  align-items: center;
  justify-items: center;
  border-radius: 20px;
  background-color: #2ec3f7;
  padding: 1px;
  height: 20px;
  width: 20px;
}
.messageSender {
  font-size: small;
  font-weight: bold;
  color: #395e9d;
}

.sentMessage::after,
.sentMessageImage::after {
  content: "";
  position: absolute;
  top: 0;

  left: 102%;
  width: 0;
  height: 0;
  border: 31px solid transparent;
  border-top-color: #395e9d;
  border-bottom: 0;
  border-left: 0;
  margin-left: -15.5px;
  margin-bottom: -31px;
}

.sentMessageImage {
  align-self: flex-end;
  margin-top: 20px;
  position: relative;
  border-radius: 8px 0px 8px 8px;

  padding: 0.5rem 0.875rem;

  background-color: #395e9d;
}

.sentMessageImage > img {
  max-width: 200px;
}

.timeStampMessageSend {
  font-size: 12px;
  color: #395e9d;
}

.timeStampMessageRecieve {
  font-size: 12px;
  color: #333333;
}

.messageWrapperSend {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
}

.messageWrapperRecieve {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 10px;
}

.abcde input[type="search"] {
  margin: 0px !important;
  width: 90% !important;
}

.infoBanner {
  background-color: #fafeff;
  border: 1px solid #2bc2f7;
  border-radius: 5px;
  width: 90% !important;
}
.justDropShadow {
  box-shadow: 2px 2px 3px 3px rgba(80, 110, 131, 0.19);
}
.contain_width {
  width: max-content;
  min-width: 300px !important;
  outline: transparent !important;
  outline-width: 0px !important;
  box-shadow: none !important;
  outline-width: 0px !important;
}

.widthIncrease {
  transition: width 1s ease-in-out;
}

.react-add-to-calendar__button {
  padding: 5px !important;
  color: white !important;
  font-size: small;
  background-color: #395e9d !important;
  border: none !important;
}
.react-add-to-calendar__dropdown {
  z-index: 999 !important;
  width: 100% !important;
  border-radius: 4px !important;
}

.react-add-to-calendar__dropdown ul {
  padding: 0px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 0.5rem !important;
}

.react-add-to-calendar__dropdown ul li a {
  color: #395e9d !important;
  font-size: small;
}

.blue_header thead tr {
  background-color: #395e9d !important;
  color: white !important;
}

.blue_header {
  overflow-x: auto;
}

.selectReports {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1) !important;
  background-color: #fff !important;
  border-radius: 10px;
  outline: transparent !important;
  outline-width: 0px !important;
  border: none !important;
  outline-width: 0px !important;
}
.maxContent {
  width: max-content;
}
